<template>
  <div class="v3p-container" :class="[variant]">
    <div
      v-if="$slots.header || icon || $slots['header-text'] || title || expandable"
      class="v3p-header-container"
      :class="{ sticky: variant === 'main' }"
    >
      <slot name="header">
        <component
          :is="expandable ? $options.components.V3Button : 'div'"
          class="v3p-header"
          :class="{ expanded }"
          @click="onTitleClick"
        >
          <slot name="header-content" v-bind="{ expanded }">
            <div class="v3p-header-content">
              <slot name="header-icon">
                <component v-if="icon" :is="icon" class="v3p-header-icon" :style="iconStyles" />
              </slot>
              <slot name="header-text">
                <span :class="{ 'pad-left': !icon }">{{ title }}</span>
              </slot>
            </div>
          </slot>
          <div v-if="expandable" class="v3p-expand" :class="{ expanded }" @click.stop="toggleExpanded">
            <slot name="expand" v-bind="{ expanded }">
              <template v-if="variant === 'main'">{{ expanded ? '-' : '+' }}</template>
              <template v-if="variant === 'secondary'">
                <IconArrowBack class="v3p-expand-icon" :class="{ expanded }" />
              </template>
            </slot>
          </div>
        </component>
      </slot>
    </div>
    <div class="v3p-content">
      <slot />
    </div>
  </div>
</template>

<script>
import IconArrowBack from '@/components/UI/IconArrowBack.vue'

import V3Button from './V3Button.vue'

export default {
  name: 'V3Panel',
  components: { IconArrowBack, V3Button },
  props: {
    expandable: Boolean,
    expanded: Boolean,
    icon: Object,
    iconColor: String,
    title: String,
    variant: { type: String, default: 'main' },
    expandOnTitleClick: { type: Boolean, default: true },
  },
  computed: {
    iconStyles() {
      return {
        color: this.iconColor,
      }
    },
  },
  methods: {
    onTitleClick() {
      if (this.expandable && this.expandOnTitleClick) {
        this.toggleExpanded()
      }
      this.$emit('titleClick')
    },
    toggleExpanded() {
      this.$emit('update:expanded', !this.expanded)
    },
  },
}
</script>

<style scoped lang="scss">
.v3p {
  &-expand {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: #7a7493;
    transition: color 0.2s ease-in-out;
    width: 0.6em;
    text-align: center;
    line-height: 1;

    &.expanded {
      color: #fff;
    }

    &-icon {
      transform: rotate(-180deg);
      transition: transform 0.2s ease-in-out;

      &.expanded {
        transform: rotate(-90deg);
      }
    }
  }

  &-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-right: 0.5rem;
    padding-left: 0;
    line-height: 1;

    &-container {
      font-size: 0.6875rem;
      background-color: #141319;

      &.sticky {
        position: sticky;
        top: -1px;
        z-index: 1;
      }
    }

    &-content {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      color: #8e8b9a;
      transition: color 0.2s ease-in-out;
      text-transform: uppercase;
    }

    &.expanded &-content {
      color: #fff;
    }

    &-icon {
      color: #7a7493;
      height: 0.75rem;
      margin-right: 0.5rem;
      transition: color 0.2s ease-in-out;
      margin-left: 0.5rem;
    }

    &.expanded &-icon {
      color: #e03a47;
    }
  }

  &-content {
    background-color: #17161d;
    filter: drop-shadow(0 3px 8px rgb(11 16 20 / 69%));
  }
}

.pad-left {
  padding-left: 0.5rem;
}
</style>
