<template>
  <div class="map-controls" :class="{ playable }">
    <button type="button" class="play-button" :class="{ play: !playing, pause: playing }" @click="togglePlay()">
      <IconPlayCircle v-if="!playing" />
      <IconPauseCircle v-if="playing" />
    </button>

    <V3ScrollableContainer
      horizontal
      ref="container"
      class="slider-container"
      :style="{ '--scale': state.timeline.scale }"
    >
      <vue-slider
        ref="slider"
        :value="sliderValue"
        v-bind="sliderOptions"
        :min="sliderMin"
        :max="sliderMax"
        :marks="marks"
        :process="getProcesses"
        @click.native="onClick"
        @drag-start="onDragStart"
        @dragging="onDragging"
        @drag-end="onDragEnd"
      >
        <template #process="{ index, style: { className, title, ...style } }">
          <div v-if="index === 0" class="vue-slider-rail-before" />
          <div
            class="vue-slider-process"
            :class="className"
            :key="`process-${index}`"
            :title="title"
            :style="convertToVars(style)"
          />
        </template>
        <template #mark="{ label, pos, event, agent, ...props }">
          <div
            class="vue-slider-mark"
            :class="[getEventRole(event), event.type]"
            :style="{ left: `${pos}%` }"
            @mouseover="onMarkerMouseOver(event, $event)"
            @mouseout="onMarkerMouseOut(event, $event)"
            @click.prevent.stop="onMarkerClick(event, $event)"
          >
            <div class="vue-slider-mark-step" :class="event.sidebar ? 'sidebar' : ''">
              <img v-if="event.type === 'plant'" class="vue-slider-mark-icon" src="/images/spike/spike-minimap.png" />
              <IconDefuse v-if="event.type === 'defuse'" class="vue-slider-mark-icon" />
              <img
                v-if="event.type === 'utility'"
                class="vue-slider-mark-icon"
                :src="agent.abilities[event.ability_slot].displayIcon"
              />
              <IconBookmark v-if="event.type === 'mark'" class="vue-slider-mark-icon" />
            </div>
            <div class="vue-slider-mark-label">
              {{ event.round_time_millis | formatTime }} {{ label }}
              <pre hidden>{{ props }}</pre>
            </div>
          </div>
        </template>
      </vue-slider>
    </V3ScrollableContainer>

    <b-form-select
      class="playback-speed"
      size="sm"
      :value="playbackRate"
      @change="$emit('update:playbackRate', $event)"
    >
      <b-form-select-option v-for="rate in supportedPlaybackRates" :key="rate" :value="rate">
        x{{ rate }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import px from 'vue-types'

import V3ScrollableContainer from '@/components/Map/components/v3dafi/V3ScrollableContainer.vue'
import alignRoundTime from '@/components/Map/utils/alignRoundTime'
import IconBookmark from '@/components/UI/IconBookmark.vue'
import IconDefuse from '@/components/UI/IconDefuse.vue'

import IconPauseCircle from '../../UI/IconPauseCircle.vue'
import IconPlayCircle from '../../UI/IconPlayCircle.vue'
import exposedDataState from '../mixins/exposedDataState.js'
import { pxEvents, pxNullable } from '../types.js'
import formatTime from '../utils/formatTime.js'

export default {
  name: 'Timeline',

  mixins: [exposedDataState],

  components: {
    IconBookmark,
    V3ScrollableContainer,
    IconPauseCircle,
    IconPlayCircle,
    IconDefuse,
    VueSlider,
  },

  props: {
    events: pxNullable(pxEvents()),
    playable: px.bool.def(false),
    playbackRate: px.number.def(1.0),
    playing: px.bool.def(false),
    roundDuration: px.integer.def(0),
    selectable: px.bool.def(false),
    supportedPlaybackRates: Array,
    userMarks: px.arrayOf(px.object).def([]),
  },

  data() {
    return {
      currentFrame: 0,
      selecting: false,
      selectionStart: null,
      selectionEnd: null,
      sliderWidth: 0,
    }
  },

  mounted() {
    this.interval = setInterval(this.updateSliderWidth, 500)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },

  filters: {
    formatTime,
  },

  computed: {
    alignRoundTime() {
      return alignRoundTime(this)
    },
    marks() {
      return Object.freeze(
        Object.fromEntries(
          [
            ...(this.events?.map(event => {
              let label
              const agent =
                event.match_player_id && this.data.agents[this.data.matchPlayers[event.match_player_id].agent_id]
              switch (event.type) {
                case 'kill':
                  label = this.data.agents[this.data.matchPlayers[event.killer.match_player_id].agent_id].name
                  break
                case 'death':
                  label = this.data.agents[this.data.matchPlayers[event.victim.match_player_id].agent_id].name
                  break
                case 'plant':
                  label = `plant ${this.data.rounds[event.round_id].plant_site}`
                  break
                case 'defuse':
                  label = `defuse ${this.data.rounds[event.round_id].plant_site}`
                  break
                case 'utility':
                  if (!this.singleRound) return
                  label = agent.abilities[event.ability_slot].displayName
                  break
                default:
                  return
              }
              const time = this.alignRoundTime(event)
              if (time == null) return // skip events that don't have a time - e.g. no plant when order by plant
              return [
                time,
                {
                  label,
                  event: event,
                  agent,
                },
              ]
            }) || []),

            ...this.userMarks
              .filter(mark => this.singleRound?.id && mark.round_id === this.singleRound.id)
              .map(mark => [
                this.alignRoundTime({
                  round_time_millis: mark.round_time_millis,
                  round_id: mark.round_id,
                }),
                {
                  label: '',
                  event: { type: 'mark', round_time_millis: mark.round_time_millis, role: mark.type },
                },
              ]),
          ].filter(e => e && e[0] >= this.sliderMin && e[0] <= this.sliderMax)
        )
      )
    },

    singleRound() {
      return this.data.rounds[this.singleRoundId]
    },

    processes() {
      const processes = []
      const matchEnd = this.singleRound
        ? this.alignRoundTime({
            round_time_millis: this.roundDuration,
            round_id: this.singleRound.id,
          })
        : null
      // if we have plant that means it's single round and we can display planting
      if (this.singleRound?.plant) {
        const pt = this.alignRoundTime({
          round_time_millis: this.singleRound.plant.round_time_millis,
          round_id: this.singleRound.id,
        })
        const df = this.alignRoundTime({
          round_time_millis: this.singleRound.defuse?.round_time_millis,
          round_id: this.singleRound.id,
        })
        // planting
        processes.push([pt - 4000, pt, { className: 'planting' }])
        // countdown - 45s or defusal or elimination
        processes.push([pt, Math.min(pt + 45000, df || matchEnd - 7000), { className: 'planted' }])
      } else if (this.selected.alignRounds === 'plant') {
        // planting
        processes.push([-4000, 0, { className: 'planting' }])
      }

      if (this.singleRound) {
        // extra
        processes.push([matchEnd - 7000, matchEnd, { className: 'extra' }])
      } else if (this.selected.alignRounds === 'end') {
        // extra
        processes.push([-7000, 0, { className: 'extra' }])
      }

      if (this.selecting && this.selectionStart != null && this.selectionEnd != null) {
        processes.push([
          Math.min(this.selectionStart, this.selectionEnd),
          Math.max(this.selectionStart, this.selectionEnd),
          {
            className: 'selection',
            title: `${formatTime(Math.min(this.selectionStart, this.selectionEnd))} - ${formatTime(
              Math.max(this.selectionStart, this.selectionEnd)
            )}`,
          },
        ])
      } else if (this.selected.time) {
        processes.push([
          this.selected.time.start_time_millis,
          this.selected.time.end_time_millis,
          {
            className: 'selection',
            title: `${formatTime(this.selected.time.start_time_millis)} - ${formatTime(
              this.selected.time.end_time_millis
            )}`,
          },
        ])
      }

      if (this.playable) {
        const start = Math.max(this.sliderMin, Math.min(this.sliderMax - 200, this.currentTime - 100))
        const end = start + 200
        processes.push([
          start,
          end,
          { className: `current ${this.playing ? 'playing' : 'paused'}`, title: formatTime(this.currentTime) },
        ])
      }

      return processes
        .map(([start, end, style]) => {
          if (start == null || end == null || Number.isNaN(start) || Number.isNaN(end)) {
            return false
          }
          if (end < this.sliderMin || start > this.sliderMax) {
            return false
          }
          const newStart =
            (100 * (Math.max(this.sliderMin, start) - this.sliderMin)) / (this.sliderMax - this.sliderMin)
          const newEnd = (100 * (Math.min(this.sliderMax, end) - this.sliderMin)) / (this.sliderMax - this.sliderMin)
          return [newStart, newEnd, style]
        })
        .filter(Boolean)
    },

    singleRoundId() {
      return (
        this.selected.rounds &&
        Object.entries(this.selected.rounds).length === 1 &&
        Object.keys(this.selected.rounds).pop()
      )
    },

    sliderMin() {
      switch (this.selected.alignRounds) {
        case 'start':
          return 0
        case 'end':
          return -this.roundDuration
        case 'plant': {
          // const min = Math.min.apply(Math, Object.keys(this.marks).map(Number))
          // const max = Math.max.apply(Math, Object.keys(this.marks).map(Number))
          // const offset = (this.roundDuration - (max - min)) / 2
          // if (Math.abs(min) > Math.abs(max)) return Math.floor(min - offset)
          // return Math.floor(max - this.roundDuration + offset)
          return Math.floor(-this.roundDuration / 2)
        }
      }
      return 0
    },

    sliderMax() {
      return this.sliderMin + this.roundDuration
    },

    sliderOptions() {
      return {
        min: 0,
        height: null,
        dataLabel: 'label',
        dataValue: 'value',
        interval: 1,
        tooltip: 'always',
        tooltipFormatter: v => formatTime(v),
        dragOnClick: true,
        clickable: this.playable,
        disabled: false,
        style: {
          '--duration': this.roundDuration,
          '--min-value': this.sliderMin,
          '--max-value': this.sliderMax,
          '--cur-value': this.currentTime,
          '--width': `${this.sliderWidth}px`,
        },
      }
    },

    sliderValue() {
      return Math.min(this.sliderMax, Math.max(this.sliderMin, this.currentTime))
    },
  },

  methods: {
    updateSliderWidth() {
      const width = this.$refs.slider?.$el?.clientWidth
      if (width !== this.sliderWidth) {
        this.sliderWidth = width
      }
    },
    getEventRole(event) {
      switch (event.type) {
        case 'kill':
          return this.data.roundTeams[event.killer.round_team_id].role
        case 'death':
          return this.data.roundTeams[event.victim.round_team_id].role
        case 'plant':
        case 'defuse':
        case 'utility':
          return this.data.roundTeams[event.round_team_id].role
        case 'mark':
          return event.role ? event.role : 'neutral'

        default:
          throw new Error(`Unhandled event type ${event.type}`)
      }
    },

    getProcesses() {
      return this.processes
    },

    onClick(event) {
      let el = event.target
      while (el && el !== this.$refs.slider.$el) {
        if (el.classList.contains('vue-slider-rail')) {
          break
        }
        el = el.parentElement
      }
      if (!el) {
        return
      }
      const r = el.getBoundingClientRect()
      const perc = (event.clientX - r.x) / r.width
      let time
      if (el === this.$refs.slider.$el) {
        if (perc < 0.5) {
          time = this.sliderMin
        } else {
          time = this.sliderMax
        }
      } else {
        time = Math.max(
          this.sliderMin,
          Math.min(this.sliderMax, Math.round(this.sliderMin + perc * this.roundDuration))
        )
        if (this.selected.time !== null) {
          time = Math.max(this.selected.time.start_time_millis, Math.min(this.selected.time.end_time_millis - 1, time))
        }
      }
      this.currentTime = time
    },

    onDragStart() {
      if (!this.selectable) {
        return
      }

      this.selecting = true
      this.selectionStart = this.selectionEnd = null
    },

    onDragging(value) {
      if (this.selectable) {
        if (this.selectionStart == null) {
          this.selectionStart = value
        }
        this.selectionEnd = value
      } else {
        this.currentTime = this.selected.time
          ? Math.max(this.selected.time.start_time_millis, Math.min(this.selected.time.end_time_millis - 1, value))
          : value
      }
    },

    onDragEnd() {
      if (!this.selecting || !this.selectable) {
        return
      }
      this.selecting = false
      const start_time_millis = Math.min(this.selectionStart, this.selectionEnd)
      const end_time_millis = Math.max(this.selectionStart, this.selectionEnd)
      const newTime = start_time_millis < end_time_millis ? Object.freeze({ start_time_millis, end_time_millis }) : null
      if (this.selected.time !== newTime) {
        this.selected.time = newTime
      }
    },

    togglePlay() {
      if (this.playable) {
        this.$emit('update:playing', !this.playing)
      }
    },

    onMarkerClick(event) {
      this.currentTime = event.round_time_millis
      this.selecting = false
    },

    onMarkerMouseOver(event) {
      this.$emit('update:hovered', event.id)
    },

    onMarkerMouseOut() {
      this.$emit('update:hovered', null)
    },

    convertToVars(styles) {
      return Object.fromEntries(Object.entries(styles).map(([key, value]) => [`--${key}`, value]))
    },
  },
}
</script>

<style scoped lang="scss">
$primary-active: #bd2d40;
$slider-height: 3.25rem;
$icon-size: 1.5rem;

@import './Timeline';

.slider-container {
  --px-per-ms: calc(1px / 500);

  flex: 1 1 auto;
  padding: 1rem 0 1.5rem;
  background: black;
}

.vue-slider {
  flex: 1;
  height: $slider-height !important;
  box-sizing: border-box;

  &-mark {
    width: 0;

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 5px;
      height: 5px;
      border-radius: 2px;
      filter: drop-shadow(0 3px 4.5px rgb(11 16 20 / 46%));
      background-color: var(--color);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover,
    &:active,
    &.active {
      &::before {
        opacity: 1;
      }
    }

    &.atk {
      --color: #da6d5c;
      --border-color: #3d150f;
    }

    &.def {
      --color: #24d8ba;
      --border-color: #083b36;
    }

    &.neutral {
      --color: #a4a2ad;
    }

    &-step {
      filter: drop-shadow(0 3px 4.5px rgb(11 16 20 / 46%));
    }

    &-label {
      color: var(--color);
    }

    &.kill,
    &.death {
      z-index: 9;

      .vue-slider-mark-step {
        top: 50%;
        width: 5px;
        margin-left: -2.5px;
        height: 30px;
        margin-top: -15px;
        border-radius: 2px;
        background-color: var(--color);
        border: 1px solid var(--border-color);
      }
    }

    &.mark {
      padding: 0;
      background: none;
      z-index: 9;

      &.atk {
        --color: #ac493a;
      }

      &.def {
        --color: #099b83;
      }

      &.neutral {
        --color: #a4a2ad;
      }

      .vue-slider-mark-step {
        top: -5px;
        width: 1rem;
        height: auto;
        margin-left: -0.5rem;
        color: var(--color);
      }
    }

    &.utility {
      z-index: 10;

      &.atk .vue-slider-mark-step {
        top: auto;
        bottom: 2px;
        color: #ac493a;
        background-color: #ac493a;
      }

      &.def .vue-slider-mark-step {
        top: 2px;
        color: #099b83;
        background-color: #099881;
      }
    }

    &.plant,
    &.defuse {
      z-index: 15;
    }

    &.plant,
    &.defuse,
    &.utility {
      .vue-slider-mark-step {
        position: absolute;
        width: $icon-size;
        left: (-$icon-size/2);
        height: $icon-size;
        display: flex;
        padding: 2px;
        border-radius: 12px;
        filter: drop-shadow(0 3px 4.5px #0b1014);
        border: 2px solid #000;

        img,
        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;

          &::v-deep path {
            fill: currentcolor;
          }
        }
      }
    }

    &.plant &-step,
    &.defuse &-step {
      top: 50% !important;
      bottom: auto;
      margin-top: (-$icon-size / 2);
      z-index: 15;
      border-radius: 100%;
      filter: drop-shadow(0 3px 4.5px #0b1014);
      background-color: #fff;
      border: 2px solid #000;
    }

    &.plant &-step {
      color: #ac493a !important;
      padding: 2px !important;

      img {
        filter: invert(34%) sepia(64%) saturate(877%) hue-rotate(326deg) brightness(85%) contrast(84%);
      }
    }

    &.defuse &-step {
      color: #099b83 !important;
      padding: 4px !important;

      img {
        filter: invert(40%) sepia(74%) saturate(712%) hue-rotate(126deg) brightness(97%) contrast(93%);
      }
    }

    &-label {
      font-size: 10px;
      top: -1.375rem !important;
      margin: 0;
    }
  }

  &::v-deep {
    .vue-slider-process {
      transition-duration: 0.1s !important;

      &::after {
        position: absolute;
        bottom: -1.375rem;
        content: attr(title);
        white-space: nowrap;
        margin: 0;
        font-size: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.map-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 0.5rem;

  .vue-slider::v-deep {
    .vue-slider-dot {
      display: none;
    }
  }
}

.play-button {
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  padding: 5px;
  transition: color 0.2s ease-in-out;

  .map-controls:not(.playable) & {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.play {
    color: #797393;
  }

  &.pause {
    color: #a4a2ad;
  }
}

.playback-speed {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0 0 0 0.25rem;
  width: 44px;
  font-family: inherit;
  font-size: 10px;
  cursor: pointer;
  color: #9894b1;
  line-height: 2rem;
  border-radius: 3px;
  background-color: #191822;
  background-image: none;
  text-align: center;
  outline: none;
  min-width: 44px;
  max-width: 44px;
  text-indent: 1px;
  text-overflow: '';

  ::-ms-expand {
    display: none;
  }

  .map-controls:not(.playable) & {
    display: none;
  }
}

.vue-slider {
  --px-per-ms: calc(var(--width) / var(--duration));
  --minor-grid: max(0px, calc(var(--px-per-ms) * 1000 - 1px));

  background-color: black;
  background-image: linear-gradient(
    0deg,
    rgb(27 211 233 / 30.2%) 0%,
    rgb(37 175 204 / 28.6%) 9%,
    rgb(40 222 244 / 0%) 100%
  );
  border: 3px solid #1e3948;
  border-radius: 6px;
  padding: 0 ($icon-size / 2) !important;
  width: calc(100% * var(--scale)) !important;

  &::v-deep {
    .vue-slider {
      &-rail {
        background-size: calc(1px + var(--minor-grid));
        background-position-x: left;
        background-image: none;

        &-before,
        &-after {
          content: '';
          position: absolute;
          left: 0;
          top: -3px;
          bottom: -3px;
          right: 0;
          display: block;
        }

        &-before {
          z-index: 2;
          background: repeating-linear-gradient(
            -90deg,
            rgb(0 0 0 / 50%) 0 var(--minor-grid),
            transparent var(--minor-grid) calc(var(--minor-grid) + 1px)
          );
          background-clip: content-box;
          border: 3px solid transparent;
          background-position-x: left;
        }
      }
    }
  }

  &-process {
    border-top: 3px solid;
    border-bottom: 3px solid;
    left: var(--left);
    width: var(--width);
    top: -3px;
    bottom: -3px;
    background-image: initial;
    background-size: initial;
    background-clip: content-box;

    &.fromStart {
      border-left: 3px solid;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      width: auto;
      left: -3px;
    }

    &.tillEnd {
      border-right: 3px solid;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      width: auto;
      right: -3px;
    }

    &.planting {
      background-image: linear-gradient(0deg, #685d2e 0%, #282514 100%);
      border-color: #373621;
    }

    &.planted {
      //background: rgb(100 100 255 / 50%);
      background-image: linear-gradient(0deg, #5a1f17 0%, #1f0909 100%);
      border-color: #3f1d1d;
    }

    &.extra {
      //background: rgb(242 161 66 / 50%);
      background-image: linear-gradient(0deg, #2f2d3b 0%, #000 100%);
      border-color: #2f2d3b;
    }

    &.selection {
      //background: rgb(123 231 32 / 50%);
      background-color: #ffe400;
      color: #ffe400;
      border-color: transparent;
      opacity: 0.451;
      z-index: 3;
    }

    &.current {
      top: 50%;
      margin-top: -30px;
      left: calc(var(--left) + var(--width) / 2 - 2px);
      width: 4px;
      height: 60px;
      border-radius: 2px;
      color: #a4a2ad;
      background-color: #a4a2ad;
      z-index: 50;
      filter: drop-shadow(0 3px 4.5px #0b1014);
      border: 1px solid #7a7493;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

      &::after {
        top: -1rem;
        bottom: auto;
      }

      &.paused {
        color: #797393;
        background-color: #797393;
      }

      &.playing {
        color: #a4a2ad;
        background-color: #a4a2ad;
      }
    }
  }

  &-mark {
    z-index: 3;

    &.utility {
      z-index: 6;
    }
  }
}
</style>
