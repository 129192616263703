export default {
  inject: ['exposed', 'mapController'],
  computed: {
    currentTime: {
      get() {
        return this.exposed.currentTime
      },
      set(newTime) {
        this.exposed.currentTime = newTime
      },
    },
    data() {
      return this.exposed.data
    },
    mapToolEvents() {
      return this.mapController.filteredEvents
    },
    mapToolFilters() {
      return this.mapController.mapToolFilters
    },
    mapMode: {
      get() {
        return this.exposed.state.mapMode
      },
      set(val) {
        if (this.exposed.state[val]) {
          this.exposed.state.mapMode = val
        } else {
          throw new Error(`Invalid map mode ${val}`)
        }
      },
    },
    selected() {
      return this.state.selected
    },
    state() {
      return this.exposed.state[this.exposed.state.mapMode]
    },
    mapSettings() {
      return this.exposed.settings
    },
  },
}
