<template>
  <FormController class="form" :submit="submit" data-form-type="other">
    <template #default>
      <b-form-group class="flex-fill" label="Twitch/Youtube url" label-for="vod_url">
        <b-form-input
          id="vod_url"
          v-model.trim="$v.form.vod_url.$model"
          type="text"
          :class="{
            'is-valid': $v.form.vod_url.$model !== '',
            'is-invalid': $v.form.vod_url.$error,
          }"
        />
        <div class="text-danger" v-if="$v.form.vod_url.$error && !$v.form.vod_url.required">Field is required</div>
        <div class="text-danger" v-if="$v.form.vod_url.$error && !$v.form.vod_url.minLength">
          Vod url must have at least {{ $v.form.vod_url.$params.minLength.min }} letters.
        </div>
      </b-form-group>

      <div class="d-flex justify-content-between">
        <b-form-group class="flex-fill mr-4" label="Vod start time (Format: hh:mm:ss)" label-for="start_time">
          <b-form-input
            id="start_time"
            v-model.trim="form.start_time"
            type="text"
            v-mask="'##:##:##'"
            title="Enter the time 5 seconds before the first round."
            v-b-tooltip.focus.noninteractive
          />
        </b-form-group>

        <b-form-group class="flex-fill" label="Vod end time (Format: hh:mm:ss)" label-for="end_time">
          <b-form-input
            id="end_time"
            v-model.trim="form.end_time"
            type="text"
            v-mask="'##:##:##'"
            title="Enter the time once you see team ATK/DEF won/lost."
            v-b-tooltip.focus.noninteractive
          />
        </b-form-group>
      </div>

      <b-button type="submit" variant="warning" :disabled="$v.form.$invalid"> Submit </b-button>
    </template>
  </FormController>
</template>

<script>
import px from 'vue-types'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

import { uploadMatchReplay } from '@/api/matches/index.js'
import FormController from '@/components/controllers/FormController.vue'
import { duration_str_to_seconds } from '@/helper.js'
import mixpanel from '@/mixpanel.js'
import { getVodUrlDetails } from '@/utils/getVodUrlDetails.js'

export default {
  mixins: [validationMixin],
  components: { FormController },
  props: {
    matchId: px.string,
  },

  data() {
    return {
      form: {
        vod_url: '',
        start_time: '',
        end_time: '',
      },
    }
  },

  validations: {
    form: {
      vod_url: {
        required,
        minLength: minLength(10),
      },
    },
  },

  computed: {
    isCollegiate() {
      return this.$route.params.isCollegiate || false
    },
  },

  methods: {
    async submit() {
      const { vod_url } = this.form
      const start_time = duration_str_to_seconds(this.form.start_time)
      const end_time = duration_str_to_seconds(this.form.end_time)
      const vodUrlDetails = getVodUrlDetails(vod_url)

      const replay = await uploadMatchReplay({
        matchId: this.matchId,
        videoPlatform: vodUrlDetails.vodPlatform,
        videoId: vodUrlDetails.vodId,
        videoStartTime: start_time,
        videoEndTime: end_time,
        isCollegiate: this.isCollegiate,
      })

      mixpanel.track_vod_upload(
        {
          vod_url,
          start_time,
          end_time,
        },
        this.matchId
      )

      return replay
    },
  },
}
</script>

<style></style>
