<template>
  <div class="v3-scrollable" :class="{ horizontal, vertical, scrolling }" @mousedown.left="onMouseDown">
    <slot />
  </div>
</template>

<script>
const MIN_DELTA = 10

export default {
  name: 'V3ScrollableContainer',
  props: {
    horizontal: Boolean,
    vertical: Boolean,
  },
  data() {
    return {
      potentialScrolling: false,
      scrolling: false,
    }
  },
  watch: {
    potentialScrolling(val) {
      if (val) {
        this.hook()
      } else {
        this.unhook()
      }
    },
    scrolling(val) {
      if (!val) {
        this.potentialScrolling = false
      }
    },
  },
  beforeDestroy() {
    this.unhook()
  },
  methods: {
    hook() {
      document.addEventListener('mousemove', this.onMouseMove, { passive: true })
      document.addEventListener('mouseup', this.onMouseUp, { passive: false })
    },
    unhook() {
      document.removeEventListener('mousemove', this.onMouseMove, { passive: true })
      document.removeEventListener('mouseup', this.onMouseUp, { passive: false })
    },
    onMouseDown(e) {
      this.pos = {
        left: this.$el.scrollLeft,
        top: this.$el.scrollTop,
        x: e.clientX,
        y: e.clientY,
      }

      this.potentialScrolling = true
    },
    onMouseMove(e) {
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y

      if (this.potentialScrolling) {
        this.scrolling =
          this.scrolling || (this.horizontal && Math.abs(dx) > MIN_DELTA) || (this.vertical && Math.abs(dy) > MIN_DELTA)
      }

      if (this.scrolling) {
        if (this.vertical) {
          this.$el.scrollTop = this.pos.top - dy
        }
        if (this.horizontal) {
          this.$el.scrollLeft = this.pos.left - dx
        }
      }
    },
    onMouseUp() {
      this.potentialScrolling = false
      if (this.scrolling) {
        this.$el.addEventListener('click', this.preventClick, { passive: false, capture: true })
        setTimeout(() => {
          this.scrolling = false
          window.getSelection?.()?.removeAllRanges?.()
        })
      }
    },
    preventClick(e) {
      this.$el.removeEventListener('click', this.preventClick, { passive: false, capture: true })
      e.preventDefault()
      e.stopPropagation()
    },
  },
}
</script>

<style scoped lang="scss">
$foreground-color: #464756;
$background-color: #2f2c3b;
$size: 0.25rem;

.v3-scrollable {
  cursor: grab;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 0.125rem;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: $size;
    background-color: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 0.125rem;
    filter: drop-shadow(0 3px 8px rgb(11 16 20 / 69%));
  }

  // Firefox scroll width
  scrollbar-width: thin;

  &.horizontal {
    overflow-x: auto;

    // noinspection CssInvalidPropertyValue
    overflow-x: overlay;
  }

  &.vertical {
    overflow-y: auto;

    // noinspection CssInvalidPropertyValue
    overflow-y: overlay;
  }

  &.scrolling {
    cursor: grabbing;
    user-select: none;
  }
}
</style>
