/**
 * @param data
 * @param selected
 * @returns {(function({round_id: String, round_time_millis: Number}): number|null)}
 */
const alignRoundTime = ({ data, selected }) => {
  switch (selected.alignRounds) {
    case 'start':
      return ({ round_time_millis }) => round_time_millis
    case 'end':
      return ({ round_id, round_time_millis, round = data.rounds[round_id] }) =>
        round_time_millis - round?.round_duration_millis
    case 'plant':
      return ({ round_id, round_time_millis, round = data.rounds[round_id] }) =>
        round?.plant_round_millis != null ? round_time_millis - round?.plant_round_millis : null
  }
}

export default alignRoundTime
