export default function formatTime(miliseconds) {
  if (miliseconds == null) return null
  const negative = miliseconds < 0
  const seconds = `${Math.floor((Math.abs(miliseconds) / 1000) % 60)}`
  const minutes = `${Math.floor(Math.abs(miliseconds) / 1000 / 60)}`

  // this is specific for killfeed, where kills in the extended time are shown as +m:ss
  return `${negative ? '+' : ''}${minutes.length === 1 && !negative ? '0' + minutes : minutes}:${
    seconds.length === 1 ? '0' + seconds : seconds
  }`
}
