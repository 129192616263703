<template>
  <KillfeedItem class="utility" :event="event" :class="[grid, role]">
    <div class="player">
      <img class="agent-icon" :alt="agent.name" :src="agent.display_icon_small_url" />
      <div class="player-name">
        {{ player.name }}
      </div>
      <div class="item-container">
        <img
          class="item-icon"
          :src="ability.displayIcon"
          :title="ability.displayName"
          :class="event.sidebar ? 'item-icon-sidebar' : ''"
        />
      </div>
    </div>
  </KillfeedItem>
</template>

<script>
import px from 'vue-types'

import { pxAgent, pxEvent, pxMatchPlayer, pxPlayerData, pxRoundData, pxRoundTeam } from '../types.js'

import KillfeedItem from './KillfeedItem.vue'

export default {
  name: 'KillfeedItemPlant',
  components: { KillfeedItem },
  props: {
    agents: px.objectOf(pxAgent()).isRequired,
    event: pxEvent().isRequired,
    matchPlayers: px.objectOf(pxMatchPlayer()).isRequired,
    players: px.objectOf(pxPlayerData()).isRequired,
    rounds: px.objectOf(pxRoundData()).isRequired,
    roundTeams: px.objectOf(pxRoundTeam()).isRequired,
  },
  computed: {
    ability() {
      return this.agent.abilities[this.event.ability_slot]
    },
    agent() {
      return this.agents[this.matchPlayers[this.event.match_player_id].agent_id]
    },
    grid() {
      return this.roundTeams[this.event.round_team_id].grid
    },
    player() {
      return this.players[this.matchPlayers[this.event.match_player_id].player_id]
    },
    role() {
      return this.roundTeams[this.event.round_team_id].role
    },
  },
}
</script>

<style lang="scss" scoped>
.killfeed-item.utility {
  position: relative;
  font-size: 11px;
  color: #fff;

  .player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: (160/256 * 100%);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 8px;
  }

  .player-name {
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .agent-icon {
    position: absolute;
    bottom: 0;
    width: 23px;
    height: 24px;
    left: 0;
  }

  .item-container {
    width: 56px;
    text-align: center;

    .item-icon {
      display: inline-block;
      width: auto;
      height: 12px;
    }
  }
}
</style>
