function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-controls",class:{ playable: _vm.playable }},[_c('button',{staticClass:"play-button",class:{ play: !_vm.playing, pause: _vm.playing },attrs:{"type":"button"},on:{"click":function($event){return _vm.togglePlay()}}},[(!_vm.playing)?_c('IconPlayCircle'):_vm._e(),(_vm.playing)?_c('IconPauseCircle'):_vm._e()],1),_c('V3ScrollableContainer',{ref:"container",staticClass:"slider-container",style:({ '--scale': _vm.state.timeline.scale }),attrs:{"horizontal":""}},[_c('vue-slider',_vm._b({ref:"slider",attrs:{"value":_vm.sliderValue,"min":_vm.sliderMin,"max":_vm.sliderMax,"marks":_vm.marks,"process":_vm.getProcesses},on:{"drag-start":_vm.onDragStart,"dragging":_vm.onDragging,"drag-end":_vm.onDragEnd},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"process",fn:function(ref){
var index = ref.index;
var ref_style = ref.style;
var className = ref_style.className;
var title = ref_style.title;
var rest = objectWithoutProperties( ref_style, ["className", "title"] );
var style = rest;
return [(index === 0)?_c('div',{staticClass:"vue-slider-rail-before"}):_vm._e(),_c('div',{key:("process-" + index),staticClass:"vue-slider-process",class:className,style:(_vm.convertToVars(style)),attrs:{"title":title}})]}},{key:"mark",fn:function(ref){
var label = ref.label;
var pos = ref.pos;
var event = ref.event;
var agent = ref.agent;
var rest = objectWithoutProperties( ref, ["label", "pos", "event", "agent"] );
var props = rest;
return [_c('div',{staticClass:"vue-slider-mark",class:[_vm.getEventRole(event), event.type],style:({ left: (pos + "%") }),on:{"mouseover":function($event){return _vm.onMarkerMouseOver(event, $event)},"mouseout":function($event){return _vm.onMarkerMouseOut(event, $event)},"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onMarkerClick(event, $event)}}},[_c('div',{staticClass:"vue-slider-mark-step",class:event.sidebar ? 'sidebar' : ''},[(event.type === 'plant')?_c('img',{staticClass:"vue-slider-mark-icon",attrs:{"src":"/images/spike/spike-minimap.png"}}):_vm._e(),(event.type === 'defuse')?_c('IconDefuse',{staticClass:"vue-slider-mark-icon"}):_vm._e(),(event.type === 'utility')?_c('img',{staticClass:"vue-slider-mark-icon",attrs:{"src":agent.abilities[event.ability_slot].displayIcon}}):_vm._e(),(event.type === 'mark')?_c('IconBookmark',{staticClass:"vue-slider-mark-icon"}):_vm._e()],1),_c('div',{staticClass:"vue-slider-mark-label"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(event.round_time_millis))+" "+_vm._s(label)+" "),_c('pre',{attrs:{"hidden":""}},[_vm._v(_vm._s(props))])])])]}}])},'vue-slider',_vm.sliderOptions,false))],1),_c('b-form-select',{staticClass:"playback-speed",attrs:{"size":"sm","value":_vm.playbackRate},on:{"change":function($event){return _vm.$emit('update:playbackRate', $event)}}},_vm._l((_vm.supportedPlaybackRates),function(rate){return _c('b-form-select-option',{key:rate,attrs:{"value":rate}},[_vm._v(" x"+_vm._s(rate)+" ")])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }