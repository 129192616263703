<template>
  <KillfeedItem class="kill" :class="[killerRole]" :event="event" :future="future">
    <div class="pad-right" :class="[victimRole]">
      <span v-if="event.is_trade" class="trade-icon" v-b-tooltip.hover.noninteractive title="Trade">⇄</span>
      <div class="player-name victim">
        {{ victimName }}
      </div>
      <img class="agent-icon" :alt="victimAgent.name" :src="victimAgent.display_icon_small_url" />
    </div>
    <div class="pad-left">
      <img
        class="agent-icon"
        :class="{ dead: event.post_mortem }"
        :alt="killerAgent.name"
        :src="killerAgent.display_icon_small_url"
      />
      <div class="player-name killer">
        {{ killerName }}
      </div>

      <div v-if="event.finishing_damage.damage_type === 'Ability'" class="item-container ability">
        <img class="item-icon" :alt="ability.displayName" :src="ability.displayIcon" />
      </div>

      <div v-if="event.finishing_damage.damage_type === 'Bomb'" class="item-container bomb">
        <div class="item-name" :data-item="event.finishing_damage.damage_item">Bomb</div>
      </div>

      <div v-if="event.finishing_damage.damage_type === 'Melee'" class="item-container melee">
        <div class="item-name" :data-item="event.finishing_damage.damage_item">Melee</div>
      </div>

      <div v-if="event.finishing_damage.damage_type === 'Weapon'" class="item-container weapon">
        <img class="item-icon" :alt="weapon.name" :src="weapon.kill_stream_icon_url" />
      </div>

      <div v-if="event.finishing_damage.damage_type === 'Fall'" class="item-container weapon">Fall</div>
    </div>
  </KillfeedItem>
</template>

<script>
import px from 'vue-types'

import reportException from '../../../utils/reportException.js'
import { pxAgent, pxKillEvent, pxMatchPlayer, pxPlayerData, pxRoundTeam, pxWeapon } from '../types.js'

import KillfeedItem from './KillfeedItem.vue'

export default {
  name: 'KillfeedItemKill',
  components: { KillfeedItem },
  props: {
    agents: px.objectOf(pxAgent()).isRequired,
    event: pxKillEvent().isRequired,
    future: Boolean,
    matchPlayers: px.objectOf(pxMatchPlayer()).isRequired,
    players: px.objectOf(pxPlayerData()).isRequired,
    roundTeams: px.objectOf(pxRoundTeam()).isRequired,
    weapons: px.objectOf(pxWeapon()).isRequired,
  },
  computed: {
    ability() {
      return this.killerAgent.abilities[this.event.finishing_damage.damage_item]
    },
    killerAgent() {
      return this.agents[this.matchPlayers[this.event.killer.match_player_id].agent_id]
    },
    killerGrid() {
      return this.roundTeams[this.event.killer.round_team_id].grid
    },
    killerName() {
      return this.players[this.matchPlayers[this.event.killer.match_player_id].player_id].name
    },
    killerRole() {
      return this.roundTeams[this.event.killer.round_team_id].role
    },
    victimAgent() {
      return this.agents[this.matchPlayers[this.event.victim.match_player_id].agent_id]
    },
    victimGrid() {
      return this.roundTeams[this.event.victim.round_team_id].grid
    },
    victimName() {
      return this.players[this.matchPlayers[this.event.victim.match_player_id].player_id].name
    },
    victimRole() {
      return this.roundTeams[this.event.victim.round_team_id].role
    },
    weapon() {
      if (!this.weapons[this.event.finishing_damage.damage_item.toLowerCase()]) {
        reportException(
          `Missing weapon ${this.event.finishing_damage.damage_item.toLowerCase()} in static assets for agent ${
            this.killerAgent.name
          }`,
          { event: this.event, agent: this.killerAgent }
        )
        return {}
      }
      return this.weapons[this.event.finishing_damage.damage_item.toLowerCase()]
    },
  },
}
</script>

<style lang="scss" scoped>
.killfeed-item.kill {
  position: relative;
  font-size: 11px;
  color: #fff;

  // positioning
  .pad-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: (132 / 256 * 100%);
  }

  .pad-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: (160/256 * 100%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      clip-path: polygon(calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 0% 100%, 0 0);
      z-index: -10;
    }
  }

  // shadows
  .pad-right,
  .pad-left {
    filter: drop-shadow(0 3px 4px rgb(11 16 20 / 50%));
  }

  // appearance
  &.def {
    .pad-left::before {
      background-image: linear-gradient(115deg, #66bca0 0%, #66bca0 37%, #18654d 90%, #18654d 100%);
    }

    .pad-right {
      background-image: linear-gradient(90deg, #7f2e21 0%, #7f2e21 11%, #cc5b4a 99%, #cc5b4a 100%);

      &.def {
        background-image: linear-gradient(115deg, #66bca0 0%, #66bca0 37%, #18654d 90%, #18654d 100%);
      }

      &.atk {
        background-image: linear-gradient(90deg, #7f2e21 0%, #7f2e21 11%, #cc5b4a 99%, #cc5b4a 100%);
      }
    }
  }

  &.atk {
    .pad-left::before {
      background-image: linear-gradient(115deg, #cc5c4a 0%, #cc5c4a 40%, #7f2e22 90%, #7f2e22 100%);
    }

    .pad-right {
      background-image: linear-gradient(90deg, #236751 0%, #236751 11%, #4f8f7b 99%, #4f8f7b 100%);

      &.def {
        background-image: linear-gradient(115deg, #66bca0 0%, #66bca0 37%, #18654d 90%, #18654d 100%);
      }

      &.atk {
        background-image: linear-gradient(90deg, #7f2e21 0%, #7f2e21 11%, #cc5b4a 99%, #cc5b4a 100%);
      }
    }
  }
}

.pad-left,
.pad-right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pad-left {
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 8px;
}

.pad-right {
  justify-content: flex-end;
  padding-right: 27px;
  padding-left: 40px;
}

.player-name {
  white-space: nowrap;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &.victim {
    text-align: right;
  }
}

.col.left.dead {
  position: relative;

  &::after {
    content: '❌';
    position: absolute;
    color: $red;
    left: 0;
    top: 0;
    font-size: 20px;
    line-height: 32px;
    width: 32px;
    text-align: center;
    font-weight: 800;
  }
}

.agent-icon {
  position: absolute;
  bottom: 0;
  width: 23px;
  height: 24px;

  .pad-left & {
    left: 0;
  }

  .pad-right & {
    right: 0;
    transform: scaleX(-1);
  }
}

.trade-icon {
  align-self: center;
  margin-right: auto;
}

.item-container {
  width: 56px;
  text-align: center;

  .item-icon {
    display: inline-block;
    width: auto;
    height: 12px;
  }

  .item-name {
    text-align: center;
  }

  &.weapon .item-icon {
    transform: scaleX(-1);
  }
}

.killfeed-item.kill {
  &.atk {
    .item-container {
      &.weapon,
      &.ability {
        .item-icon {
          filter: invert(100%) invert(76%) sepia(70%) saturate(193%) hue-rotate(314deg) brightness(100%) contrast(98%);
        }
      }
    }
  }

  &.def {
    .item-container {
      &.weapon,
      &.ability {
        .item-icon {
          filter: invert(100%) invert(95%) sepia(7%) saturate(1123%) hue-rotate(85deg) brightness(102%) contrast(97%);
        }
      }
    }
  }
}
</style>
