<template>
  <div class="killfeed-item" :class="{ future }">
    <div class="timestamp">
      {{ displayedTime }}
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import { pxEvent } from '../types.js'
import formatTime from '../utils/formatTime.js'

export default {
  name: 'KillfeedItem',
  props: {
    event: pxEvent().isRequired,
    future: Boolean,
    rounds: Object,
  },
  computed: {
    displayedTime() {
      return formatTime(100000 - this.event.round_time_millis)
    },
  },
}
</script>

<style lang="scss" scoped>
.killfeed-item {
  display: flex;
  flex-flow: row nowrap;
  line-height: 30px;
  height: 30px;
  flex: 0 0 30px;
  min-height: 30px;
  max-height: 30px;
  cursor: pointer;

  &:nth-of-type(2n + 1) {
    .timestamp {
      background-color: #24222e;
    }
  }

  &:nth-of-type(2n) {
    .timestamp {
    }
  }

  .timestamp {
    font-size: 10px;
    color: #d7c000;
    text-align: center;
    width: 36px;
    min-width: 36px;
    max-width: 36px;
    flex: 0 0 36px;
    transition: color 0.2s ease-in-out;
  }

  &:hover,
  &.active {
    .timestamp {
      color: #fff;
    }
  }

  .content {
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    transition: filter 0.2s ease-in-out;
  }

  &.future {
    .content {
      filter: grayscale(1);
    }
  }
}

.atk .content {
  background-image: linear-gradient(115deg, #83372d 0%, #83372d 1%, #632d25 100%);
}

.def .content {
  background-image: linear-gradient(115deg, #4b9f83 0%, #4b9f83 1%, #2d6f5b 100%);
}
</style>
