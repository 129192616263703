<template>
  <button type="button" class="v3-button" :class="{ active }" :disabled="disabled" v-on="$listeners"><slot /></button>
</template>

<script>
export default {
  name: 'V3Button',
  props: {
    active: Boolean,
    disabled: Boolean,
  },
}
</script>

<style scoped lang="scss">
.v3-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  // button reset
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  user-select: none;
  padding: 0 0.25em;

  // basic sizing
  min-height: 2rem;
  min-width: 2rem;

  // nice animation
  transition: all 0.2s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
