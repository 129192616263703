<template>
  <KillfeedItem :class="[planterGrid, planterRole]" :event="event" :future="future">
    <img class="item-icon" src="/images/spike/spike-minimap.png" :title="plantSite" />
  </KillfeedItem>
</template>

<script>
import px from 'vue-types'

import { pxEvent, pxRoundData, pxRoundTeam } from '../types.js'

import KillfeedItem from './KillfeedItem.vue'

export default {
  name: 'KillfeedItemPlant',
  components: { KillfeedItem },
  props: {
    event: pxEvent().isRequired,
    future: Boolean,
    rounds: px.objectOf(pxRoundData()).isRequired,
    roundTeams: px.objectOf(pxRoundTeam()).isRequired,
  },
  computed: {
    planterGrid() {
      return this.roundTeams[this.event.round_team_id].grid
    },
    plantSite() {
      return this.rounds[this.event.round_id].plant_site
    },
    planterRole() {
      return this.roundTeams[this.event.round_team_id].role
    },
  },
}
</script>

<style lang="scss" scoped>
.item-icon {
  height: 26px;
  margin: 0 auto;
  justify-self: center;

  .atk & {
    filter: invert(100%) invert(77%) sepia(60%) saturate(161%) hue-rotate(316deg) brightness(92%) contrast(115%);
  }

  .def & {
    filter: invert(100%) invert(91%) sepia(16%) saturate(455%) hue-rotate(90deg) brightness(102%) contrast(97%);
  }
}
</style>
