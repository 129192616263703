<template>
  <V3Panel
    class="killfeed-container"
    :expandable="expandable"
    :expanded="expanded"
    @update:expanded="$listeners['update:expanded']"
    :icon="$options.components.IconTarget"
    icon-color="#e03a47"
  >
    <template #header-text>
      <span class="killfeed-label"><span class="first">Kill</span><span class="second">Feed</span></span>
    </template>

    <div class="killfeed">
      <component
        v-for="event in events"
        :is="getComponentForEventType(event.type)"
        :key="event.id"
        :agents="agents"
        :event="event"
        :future="isFuture(event)"
        :match-players="matchPlayers"
        :players="players"
        :rounds="rounds"
        :round-teams="roundTeams"
        :weapons="weapons"
        @click.native="$emit('select', event)"
      />
    </div>
  </V3Panel>
</template>

<script>
import px from 'vue-types'

import alignRoundTime from '@/components/Map/utils/alignRoundTime'

import IconTarget from '../../UI/IconTarget.vue'
import { pxAgent, pxEvent, pxMatchPlayer, pxPlayerData, pxRoundData, pxRoundTeam, pxWeapon } from '../types.js'

import KillfeedItemDefuse from './KillfeedItemDefuse.vue'
import KillfeedItemKill from './KillfeedItemKill.vue'
import KillfeedItemPlant from './KillfeedItemPlant.vue'
import KillfeedItemUtility from './KillfeedItemUtility.vue'
import V3Panel from './v3dafi/V3Panel.vue'

export default {
  name: 'Killfeed',
  components: { IconTarget, V3Panel, KillfeedItemDefuse, KillfeedItemKill, KillfeedItemPlant, KillfeedItemUtility },
  props: {
    agents: px.objectOf(pxAgent()).isRequired,
    currentTime: Number,
    data: Object,
    events: px.arrayOf(pxEvent()).isRequired,
    expandable: Boolean,
    expanded: Boolean,
    matchPlayers: px.objectOf(pxMatchPlayer()).isRequired,
    players: px.objectOf(pxPlayerData()).isRequired,
    rounds: px.objectOf(pxRoundData()).isRequired,
    roundTeams: px.objectOf(pxRoundTeam()).isRequired,
    selected: Object,
    weapons: px.objectOf(pxWeapon()).isRequired,
  },
  computed: {
    alignRoundTime() {
      return alignRoundTime(this)
    },
  },
  methods: {
    getComponentForEventType(eventType) {
      switch (eventType) {
        case 'plant':
          return 'KillfeedItemPlant'
        case 'kill':
          return 'KillfeedItemKill'
        case 'defuse':
          return 'KillfeedItemDefuse'
        case 'utility':
          return 'KillfeedItemUtility'
      }
    },
    isFuture(event) {
      return this.currentTime != null && this.alignRoundTime(event) > this.currentTime
    },
  },
}
</script>

<style lang="scss" scoped>
.killfeed {
  display: flex;
  flex-flow: column nowrap;
  padding: 0.5rem 0.25rem;

  &-label {
    font-size: 0.6875rem;
    text-transform: uppercase;

    .first {
      color: #b14b3b;
    }

    .second {
      color: #50907c;
    }
  }
}
</style>
