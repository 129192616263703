<template>
  <KillfeedItem :class="[defuserGrid, defuserRole]" :event="event" :future="future">
    <IconDefuse class="item-icon" />
  </KillfeedItem>
</template>

<script>
import px from 'vue-types'

import IconDefuse from '../../UI/IconDefuse.vue'
import { pxEvent, pxRoundTeam } from '../types.js'

import KillfeedItem from './KillfeedItem.vue'

export default {
  name: 'KillfeedItemDefuse',
  components: { IconDefuse, KillfeedItem },
  props: {
    event: pxEvent().isRequired,
    future: Boolean,
    roundTeams: px.objectOf(pxRoundTeam()).isRequired,
  },
  computed: {
    defuserGrid() {
      return this.roundTeams[this.event.round_team_id].grid
    },
    defuserRole() {
      return this.roundTeams[this.event.round_team_id].role
    },
  },
}
</script>

<style lang="scss" scoped>
.item-icon {
  height: 26px;
  margin: 0 auto;
  justify-self: center;

  .atk & {
    color: #fbc9c3;
  }

  .def & {
    color: #c3fbe1;
  }
}
</style>
